// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from "jquery";

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
window.$ = window.jQuery =require("jquery")
require("stupid-table-plugin");



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import $ from 'jquery';
import '../template';
import {} from "flatpickr";
import "select2";
import 'select2/dist/css/select2';

$( document ).on('turbolinks:load', function() {
    $(".table-sortable").stupidtable();
    $.each($('.custom-file-input'), function( index, value ) {
        if (!$("#"+$('.custom-file-input')[index].id).val() == ""){
            $(value).next('label').addClass('file-field-ok')
        }
    });
    $('.custom-file-input').change(function() {
        $(this).next('label').text($(this).val().split('\\').pop());
    })

    $('#application_form_visitor_obtained_level_ids').select2();
    $('.custom-select').select2();

})
